import { TFunction } from 'i18next';

import { ContactUsFormType } from './types';

export const getSubjectCategories = (t: TFunction) => ({
  BRAND_CENTER: t('contact.us.subject.brand-center', 'Brand Center'),
  CONTRIBUTIONS_AND_RECOGNITION: t(
    'contact.us.subject.contributions-and-recognition',
    'Contributions and recognition'
  ),
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY: t(
    'contact.us.subject.convention-or-international-assembly',
    'Convention/International Assembly'
  ),
  DISCUSSION_GROUPS: t(
    'contact.us.subject.discussion-groups',
    'Discussion Groups'
  ),
  MEMBERSHIP: t('contact.us.subject.membership', 'Membership'),
  MY_ACCOUNT_OR_PROFILE: t(
    'contact.us.subject.my-account-or-profile',
    'My Account/Profile'
  ),
  MY_ROTARY_SIGN_IN_OR_REGISTRATION: t(
    'contact.us.subject.my-rotary-sign-in-or-registration',
    'My Rotary sign in/registration'
  ),
  PROJECTS: t('contact.us.subject.projects', 'Projects'),
  ROTARY_CLUB_GOALS: t(
    'contact.us.subject.rotary-club-goals',
    'Rotary Club goals'
  ),
  UPDATE_CLUB_OR_DISTRICT_INFORMATION: t(
    'contact.us.subject.update-club-or-district-information',
    'Update Club/District information'
  ),
  YOUTH_PROGRAMS_OR_SERVICE: t(
    'contact.us.subject.youth-programs-or-service',
    'Youth programs/service'
  ),
  OTHER: t('contact.us.subject.other', 'Other'),
});

export enum SUPPORT_CENTER_RECIPIENTS {
  BRAND_CENTER_EMAIL = 'rotarysupportcenter@rotary.org',
  CONTRIBUTIONS_AND_RECOGNITION_EMAIL = 'rotarysupportcenter@rotary.org',
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL = 'rireg@rotary.org',
  DISCUSSION_GROUPS_EMAIL = 'rotarysupportcenter@rotary.org',
  MEMBERSHIP_EMAIL = 'rotarysupportcenter@rotary.org',
  MY_ACCOUNT_OR_PROFILE_EMAIL = 'rotarysupportcenter@rotary.org',
  MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL = 'data@rotary.org',
  PROJECTS_EMAIL = 'rotarysupportcenter@rotary.org',
  ROTARY_CLUB_GOALS_EMAIL = 'rotarysupportcenter@rotary.org',
  UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL = 'data@rotary.org',
  YOUTH_PROGRAMS_OR_SERVICE_EMAIL = 'rotarysupportcenter@rotary.org',
  OTHER_EMAIL = 'rotarysupportcenter@rotary.org',
}

export enum TEST_RECIPIENTS {
  BRAND_CENTER_EMAIL = 'rimailtest+Brand-Center@gmail.com',
  CONTRIBUTIONS_AND_RECOGNITION_EMAIL = 'rimailtest+Contributions-And-Recognition@gmail.com',
  CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL = 'rimailtest+Convention-Or-International-Assembly@gmail.com',
  DISCUSSION_GROUPS_EMAIL = 'rimailtest+Discussion-Groups@gmail.com',
  MEMBERSHIP_EMAIL = 'rimailtest+Membership@gmail.com',
  MY_ACCOUNT_OR_PROFILE_EMAIL = 'rimailtest+My-Account-Or-Profile@gmail.com',
  MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL = 'rimailtest+My-Rotary-SignIn-Or-Registration@gmail.com',
  PROJECTS_EMAIL = 'rimailtest+Projects@gmail.com',
  ROTARY_CLUB_GOALS_EMAIL = 'rimailtest+Rotary-Club-Goals@gmail.com',
  UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL = 'rimailtest+Update-Club-Or-District-Information@gmail.com',
  YOUTH_PROGRAMS_OR_SERVICE_EMAIL = 'rimailtest+Youth-Programs-Or-Service@gmail.com',
  OTHER_EMAIL = 'rimailtest+Other@gmail.com',
}

export const getContactSubjects = (t: TFunction) => [
  {
    name: getSubjectCategories(t).BRAND_CENTER,
  },
  {
    name: getSubjectCategories(t).CONTRIBUTIONS_AND_RECOGNITION,
  },
  {
    name: getSubjectCategories(t).CONVENTION_OR_INTERNATIONAL_ASSEMBLY,
  },
  {
    name: getSubjectCategories(t).DISCUSSION_GROUPS,
  },
  {
    name: getSubjectCategories(t).MEMBERSHIP,
  },
  {
    name: getSubjectCategories(t).MY_ACCOUNT_OR_PROFILE,
  },
  {
    name: getSubjectCategories(t).MY_ROTARY_SIGN_IN_OR_REGISTRATION,
  },
  { name: getSubjectCategories(t).PROJECTS },
  {
    name: getSubjectCategories(t).ROTARY_CLUB_GOALS,
  },
  {
    name: getSubjectCategories(t).UPDATE_CLUB_OR_DISTRICT_INFORMATION,
  },
  {
    name: getSubjectCategories(t).YOUTH_PROGRAMS_OR_SERVICE,
  },
  { name: getSubjectCategories(t).OTHER },
];

export const getTestRecipientBySubject = (t: TFunction, subject: string) => {
  switch (subject) {
    case getSubjectCategories(t).BRAND_CENTER:
      return TEST_RECIPIENTS.BRAND_CENTER_EMAIL;
    case getSubjectCategories(t).CONTRIBUTIONS_AND_RECOGNITION:
      return TEST_RECIPIENTS.CONTRIBUTIONS_AND_RECOGNITION_EMAIL;
    case getSubjectCategories(t).CONVENTION_OR_INTERNATIONAL_ASSEMBLY:
      return TEST_RECIPIENTS.CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL;
    case getSubjectCategories(t).DISCUSSION_GROUPS:
      return TEST_RECIPIENTS.DISCUSSION_GROUPS_EMAIL;
    case getSubjectCategories(t).MEMBERSHIP:
      return TEST_RECIPIENTS.MEMBERSHIP_EMAIL;
    case getSubjectCategories(t).MY_ACCOUNT_OR_PROFILE:
      return TEST_RECIPIENTS.MY_ACCOUNT_OR_PROFILE_EMAIL;
    case getSubjectCategories(t).MY_ROTARY_SIGN_IN_OR_REGISTRATION:
      return TEST_RECIPIENTS.MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL;
    case getSubjectCategories(t).PROJECTS:
      return TEST_RECIPIENTS.PROJECTS_EMAIL;
    case getSubjectCategories(t).ROTARY_CLUB_GOALS:
      return TEST_RECIPIENTS.ROTARY_CLUB_GOALS_EMAIL;
    case getSubjectCategories(t).UPDATE_CLUB_OR_DISTRICT_INFORMATION:
      return TEST_RECIPIENTS.UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL;
    case getSubjectCategories(t).YOUTH_PROGRAMS_OR_SERVICE:
      return TEST_RECIPIENTS.YOUTH_PROGRAMS_OR_SERVICE_EMAIL;
    case getSubjectCategories(t).OTHER:
      return TEST_RECIPIENTS.OTHER_EMAIL;
    default:
      return '';
  }
};

export const getSupportCenterRecipientBySubject = (
  t: TFunction,
  subject: string
) => {
  switch (subject) {
    case getSubjectCategories(t).BRAND_CENTER:
      return SUPPORT_CENTER_RECIPIENTS.BRAND_CENTER_EMAIL;
    case getSubjectCategories(t).CONTRIBUTIONS_AND_RECOGNITION:
      return SUPPORT_CENTER_RECIPIENTS.CONTRIBUTIONS_AND_RECOGNITION_EMAIL;
    case getSubjectCategories(t).CONVENTION_OR_INTERNATIONAL_ASSEMBLY:
      return SUPPORT_CENTER_RECIPIENTS.CONVENTION_OR_INTERNATIONAL_ASSEMBLY_EMAIL;
    case getSubjectCategories(t).DISCUSSION_GROUPS:
      return SUPPORT_CENTER_RECIPIENTS.DISCUSSION_GROUPS_EMAIL;
    case getSubjectCategories(t).MEMBERSHIP:
      return SUPPORT_CENTER_RECIPIENTS.MEMBERSHIP_EMAIL;
    case getSubjectCategories(t).MY_ACCOUNT_OR_PROFILE:
      return SUPPORT_CENTER_RECIPIENTS.MY_ACCOUNT_OR_PROFILE_EMAIL;
    case getSubjectCategories(t).MY_ROTARY_SIGN_IN_OR_REGISTRATION:
      return SUPPORT_CENTER_RECIPIENTS.MY_ROTARY_SIGN_IN_OR_REGISTRATION_EMAIL;
    case getSubjectCategories(t).PROJECTS:
      return SUPPORT_CENTER_RECIPIENTS.PROJECTS_EMAIL;
    case getSubjectCategories(t).ROTARY_CLUB_GOALS:
      return SUPPORT_CENTER_RECIPIENTS.ROTARY_CLUB_GOALS_EMAIL;
    case getSubjectCategories(t).UPDATE_CLUB_OR_DISTRICT_INFORMATION:
      return SUPPORT_CENTER_RECIPIENTS.UPDATE_CLUB_OR_DISTRICT_INFORMATION_EMAIL;
    case getSubjectCategories(t).YOUTH_PROGRAMS_OR_SERVICE:
      return SUPPORT_CENTER_RECIPIENTS.YOUTH_PROGRAMS_OR_SERVICE_EMAIL;
    case getSubjectCategories(t).OTHER:
      return SUPPORT_CENTER_RECIPIENTS.OTHER_EMAIL;
    default:
      return '';
  }
};

export const CONTACT_US_FORM_INITIAL_VALUES: ContactUsFormType = {
  email: '',
  firstName: '',
  lastName: '',
  primaryPhone: {
    number: '',
    country: '',
    countryId: '',
    extension: '',
  },
  country: '',
  district: '',
  club: '',
  subject: '',
  comment: '',
};
