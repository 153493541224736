import React from 'react';

import { useFormikContext } from 'formik';

import Select, { SelectProps } from '../../Formik/Select';

import { getContactSubjects, Subject } from '@domain/contact';

import { useTranslation } from '@external/react-i18next';

const generateOptions = (subjects: Subject[]) =>
  subjects.map(s => ({ label: s.name, value: s.name }));

type Props = Omit<SelectProps, 'options'>;

const ContactUsFormSubjectSelect: React.FC<Props> = props => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    const { name } = props;
    setFieldValue(name, value);
  };

  return (
    <Select
      onChange={handleChange}
      options={generateOptions(getContactSubjects(t))}
      {...props}
    />
  );
};

export default ContactUsFormSubjectSelect;
